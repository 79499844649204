export default {
	data: () => ({
		faqCategory: {
			id: 0,
			name: "",
			description: "",
			display_order: "",
			isDisplay: ""
		},
    loading: false,
		error: "",
		backUrl: "/faq-categories"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.faqCategory = {
				id: 0,
				name: "",
				description: "",
				display_order: "",
				isDisplay: ""
			};
		},
		validatefaqCategory() {
			this.faqCategory.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
		},
		update() {
			let _vm = this;
			this.axios
				.post("/faqcategory/" + this.faqCategory.id, {
					...this.faqCategory,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.faqCategory = response.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			this.axios
				.post("/faqcategory", _vm.faqCategory)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/faqcategory/" + this.$route.params.id)
				.then(function (response) {
					_vm.faqCategory = response.data.data;
				})
				.catch(function () { });
		},

	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
